exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-makoto-js": () => import("./../../../src/pages/aboutMakoto.js" /* webpackChunkName: "component---src-pages-about-makoto-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-what-is-shamisen-js": () => import("./../../../src/pages/whatIsShamisen.js" /* webpackChunkName: "component---src-pages-what-is-shamisen-js" */),
  "component---src-pages-what-is-shamisen-shamisen-introduction-js": () => import("./../../../src/pages/whatIsShamisen/shamisenIntroduction.js" /* webpackChunkName: "component---src-pages-what-is-shamisen-shamisen-introduction-js" */),
  "component---src-pages-what-is-shamisen-shamisen-naguata-js": () => import("./../../../src/pages/whatIsShamisen/shamisenNaguata.js" /* webpackChunkName: "component---src-pages-what-is-shamisen-shamisen-naguata-js" */),
  "component---src-pages-what-is-shamisen-shamisen-types-and-styles-js": () => import("./../../../src/pages/whatIsShamisen/shamisenTypesAndStyles.js" /* webpackChunkName: "component---src-pages-what-is-shamisen-shamisen-types-and-styles-js" */)
}

